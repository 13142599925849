// This an initial idea of Optimizely wrapper to work better with CoremediaRenderer
// Currently haven't tested other components but only WEBCON-1357
// We should make it modulized
import React, { useState } from 'react'
import { OptimizelyFeature } from '@ynap/optimizely-utils'

const OptimizelyCollection = (props) => {
  const { children, item } = props
  const { analyticsTextString } = item

  if (!analyticsTextString) return null

  const feature = analyticsTextString.replace(/\s/g, '').split(',') || []

  return (
    <OptimizelyFeature feature={feature}>
      {(props) => {
        const { isEnabled, variationKey, optimizely } = props
        const [isOptimizelyLoading, setIsOptimizelyLoading] = useState(!!optimizely)

        optimizely?.onReady?.()?.then?.(() => {
          setIsOptimizelyLoading(false)
        })

        if (isOptimizelyLoading || !isEnabled) {
          return null
        }

        const matchedChild = React.Children.toArray(children).find((child) => {
          const param = child?.props.item.ynapParameter

          if (!param) return false

          try {
            const { variationKey: childVariationKey } = JSON.parse(param).optimizelyParam
            return childVariationKey === variationKey
          } catch (error) {
            return false
          }
        })
        return matchedChild || null
      }}
    </OptimizelyFeature>
  )
}
OptimizelyCollection.layoutVariant = ['ab-test-collection']

export default OptimizelyCollection
