import React from 'react'
import getConfig from 'next/config'
import { iscPreset } from '@ycos/coremedia-renderer'
import CoremediaPage from '@ycos/nextjs/src/components/CoremediaPage'
import Image from '@ycos/component-image'
import HomepageLayout from '../layouts/HomepageLayout'
import { Collection, Section, ThreeColumnItemList } from '@ycos/component-columns'
import StandardVideo from '@ycos/component-video'
import CallToAction from '@ycos/component-cta'
import ContentCarousel from '@ycos/component-content-carousel'
import SaleBanner, { SaleBannerWithCategoriesTON } from '@ycos/component-sale-banner'
import { Block } from '../src/components/component-block'
import CenteredCta from '../src/components/component-cta/CenterdCta'
import TakeOver from '../src/components/component-takeover'
import OverlapSection from '../src/components/component-overlap-section'
import { OneColumn, TwoColumn, ThreeColumn, FourColumn, FourColumnHomepage, ThreeColumnMasonry } from '../src/components/component-columns'
import ImageGrid from '../src/components/component-image-grid'
import {
  TargetUrlRewriterRule,
  WirePids,
  siteMerchandisingRule,
  siteMerchandisingAdditionalCTARules,
  imagesphereUrlRule,
  addOnTargetClick,
  FetchPriority
} from '../src/rules'
import ProductCarousel from '../src/components/component-product-carousel'
import ProductFeed from '../src/components/component-product-feed'
import CMProductList from '../src/components/component-product-list/CMProductList'
import { CMCarouselCollection } from '../src/components/component-product-list/CMCarouselCollection'
import SegmentedExperience from '../src/components/component-segmented-experience'
import PreHomePageCollection from '../src/components/component-pre-homepage-block/PreHomePageCollection'
import PreHomePageBlock from '../src/components/component-pre-homepage-block/PreHomePageBlock'
import WhatsNew from '../src/components/component-whats-new-carousel'
import whatsNewRule from '@ycos/component-carousel-whats-new/src/utils/rules/whatsNewRule'
import { WhatsNewAnalyticsRule } from '../src/rules/analyticsRules'
import PromoCountdown from '../src/components/promo-countdown'
import OptimizelyCollection from '../src/components/ab-test-collection/OptimizelyCollection'

const { publicRuntimeConfig } = getConfig()
const siteMerchandisingItems = []
const optionalRules = (props) => [
  imagesphereUrlRule(publicRuntimeConfig.imageSphere.baseUrl),
  siteMerchandisingRule(siteMerchandisingItems, props.page),
  siteMerchandisingAdditionalCTARules(siteMerchandisingItems, props.page, publicRuntimeConfig),
  addOnTargetClick(props.page, publicRuntimeConfig)
]
const analyticsAttributesByPageKey = {
  prehomepage: {
    pageName: 'unisex homepage',
    pageType: 'pre-homepage',
    subcategory1: 'landing page'
  },
  homepage: {
    pageName: 'homepage',
    pageType: 'homepage',
    subcategory1: 'homepage',
    gender: 'womens'
  },
  'homepage-mens': {
    pageName: 'homepage',
    pageType: 'homepage',
    subcategory1: 'homepage',
    gender: 'mens'
  }
}
let pageKey = ''

export default CoremediaPage({
  layout: ({ children, locale, ...rest }) => {
    return (
      <HomepageLayout
        locale={locale}
        siteMerchandisingItems={siteMerchandisingItems}
        publicRuntimeConfig={publicRuntimeConfig}
        analyticsAttributes={analyticsAttributesByPageKey[pageKey]}
        {...rest}
      >
        {children}
      </HomepageLayout>
    )
  },
  getAdditionalProps: ({ req }) => ({
    products: req.products,
    tonShop: req.routeConfig?.shop,
    pageKey: req.key
  }),
  rules: (props) => {
    const { locale, webview, products, tonShop } = props
    const { isPreview, gtmAnalytics, productUrl, brand, personalisation, optimizely } = publicRuntimeConfig
    pageKey = props.pageKey

    return [
      ...iscPreset(publicRuntimeConfig, {
        gtmAnalytics,
        productUrl,
        isPreview,
        locale,
        webview,
        brand,
        products,
        personalisation,
        optimizely,
        tonShop
      }),
      WirePids(products),
      TargetUrlRewriterRule(),
      WhatsNewAnalyticsRule,
      ...optionalRules(props),
      whatsNewRule,
      FetchPriority
    ]
  },
  components: [
    // add more components here
    Block,
    ProductCarousel,
    ProductFeed,
    Collection,
    Section,
    CallToAction,
    CenteredCta,
    Collection,
    FourColumn,
    FourColumnHomepage,
    Image,
    OneColumn,
    Section,
    TwoColumn,
    ThreeColumn,
    ThreeColumnItemList,
    OverlapSection,
    Section,
    OverlapSection,
    TakeOver,
    StandardVideo,
    ThreeColumnMasonry,
    ImageGrid,
    ContentCarousel,
    CMProductList,
    CMCarouselCollection,
    SegmentedExperience,
    PreHomePageCollection,
    PreHomePageBlock,
    SaleBanner,
    WhatsNew,
    PromoCountdown,
    OptimizelyCollection,
    SaleBannerWithCategoriesTON
  ]
})
