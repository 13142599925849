import React, { useState, useEffect } from 'react'
import { createComponent } from '@ycos/fela'
import { Picture } from '@ycos/picture'

const PromoCountdownWrapper = createComponent(
  'PromoCountdownWrapper',
  () => ({
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
    margin: '0 8px',
    'screen-large': {
      margin: '0 32px',
      height: '350px'
    },
    '> div[data-automation="picture"] picture img': {
      'screen-large': {
        height: '350px',
        objectFit: 'cover',
        objectPosition: 'center'
      }
    },
    'screen-medium': {
      margin: '0 16px'
    }
  }),
  'div'
)

const CountdownContainer = createComponent(
  'CountdownContainer',
  ({ theme }) => ({
    padding: `${theme.spacingMultiplier * 3}px 0`,
    color: theme.typography.colors.PrimaryBlack,
    maxWidth: '400px',
    margin: '0 auto',
    textAlign: 'center',
    'screen-xlarge': {
      position: 'absolute',
      top: '5px',
      left: '280px',
      color: theme.typography.colors.PrimaryWhite
    },
    'screen-large': {
      position: 'absolute',
      top: '5px',
      left: '15%',
      color: theme.typography.colors.PrimaryWhite
    },
    zIndex: 2
  }),
  'div'
)

const Title = createComponent(
  'Title',
  ({ theme }) => {
    return {
      ...theme.typography.styles.Heading.B1.bp1,
      lineHeight: '40px',
      margin: '0 auto',
      letterSpacing: '1px',
      'screen-large': {
        fontSize: '40px'
      }
    }
  },
  'p'
)

const SubTitle = createComponent('SubTitle', ({ theme }) => ({
  ...theme.typography.styles.Label[1].bp1,
  margin: `${3 * theme.spacingMultiplier}px 0`,
  lineHeight: '20px'
}))

const Timer = createComponent(
  'Timer',
  ({ theme }) => ({
    ...theme.typography.styles.Heading.B1.bp1,
    display: 'flex',
    justifyContent: 'center',
    fontSize: '32px',
    fontWeight: '400',
    lineHeight: '40px',
    'screen-xlarge': {
      fontSize: '40px'
    }
  }),
  'div'
)

const Label = createComponent(
  'Label',
  ({ theme }) => ({
    ...theme.typography.styles.Label[5].bp1,
    textTransform: 'uppercase',
    letterSpacing: '1px',
    lineHeight: '16px',
    fontWeight: '400'
  }),
  'div'
)

const TimeSegment = createComponent(
  'TimeSegment',
  () => ({
    width: '64px'
  }),
  'span'
)

const Button = createComponent(
  'Button',
  ({ theme }) => ({
    ...theme.homepage.cta,
    ...theme.homepage.ctaHover,
    width: '275px',
    marginTop: `${3 * theme.spacingMultiplier}px`,
    'screen-large': {
      backgroundColor: theme.typography.colors.PrimaryWhite,
      border: 'none'
    },
    cursor: 'pointer'
  }),
  'p'
)
const DiscountText = createComponent(
  'DiscountText',
  ({ theme }) => ({
    ...theme.typography.styles.Label[1].bp1,
    lineHeight: '20px',
    fontWeight: '300',
    marginTop: `${2 * theme.spacingMultiplier}px`,
    marginBottom: 0
  }),
  'p'
)

const GradientOverlay = createComponent(
  'GradientOverlay',
  () => ({
    'screen-medium-max': {
      display: 'none'
    },
    position: 'absolute',
    width: '100%',
    background: 'linear-gradient(180deg, rgba(119, 119, 119, 0) 0%, rgba(0, 0, 0, 0.5) 100%)',
    zIndex: 1,
    top: 0,
    left: 0,
    height: '100%'
  }),
  'span'
)

const PromoCountdown = ({ item, config, onTargetClick, locale }) => {
  const { endDate, pictures = [], teaserTitle, subTitlePlain, target } = item
  if (!endDate) {
    return null
  }

  const parseDate = () => {
    const [datePart, timePart] = endDate.split(' ')
    const year = datePart.slice(0, 4)
    const month = datePart.slice(4, 6)
    const day = datePart.slice(6, 8)
    const [hours, minutes, seconds] = timePart.split(':')
    return new Date(year, month - 1, day, hours, minutes, seconds)
  }
  const calculateTimeLeft = () => {
    const now = new Date()
    const difference = parseDate() - now
    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24))
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24)
      const minutes = Math.floor((difference / 1000 / 60) % 60)
      const seconds = Math.floor((difference / 1000) % 60)
      return { days, hours, minutes, seconds }
    }
    return null
  }
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)
    return () => clearInterval(timer)
  }, [endDate])

  if (!timeLeft) {
    return null
  }

  const getLocalisedHref = () => {
    const targetHref = target?.href
    if (!targetHref) {
      return ''
    }
    const position = targetHref.indexOf('/', targetHref.indexOf('://') + 3)
    const href = `${targetHref.slice(0, position + 1)}${locale?.id}${targetHref.slice(position)}`
    return href
  }

  const renderTimeSegments = () => {
    const { days, hours, minutes, seconds } = timeLeft
    if (days > 0) {
      return (
        <>
          <TimeSegment>
            {String(days).padStart(2, '0')}
            <Label>Days</Label>
          </TimeSegment>
          :
          <TimeSegment>
            {String(hours).padStart(2, '0')}
            <Label>Hours</Label>
          </TimeSegment>
          :
          <TimeSegment>
            {String(minutes).padStart(2, '0')}
            <Label>Minutes</Label>
          </TimeSegment>
        </>
      )
    }
    return (
      <>
        <TimeSegment>
          {String(hours).padStart(2, '0')}
          <Label>Hours</Label>
        </TimeSegment>
        :
        <TimeSegment>
          {String(minutes).padStart(2, '0')}
          <Label>Minutes</Label>
        </TimeSegment>
        :
        <TimeSegment>
          {String(seconds).padStart(2, '0')}
          <Label>Seconds</Label>
        </TimeSegment>
      </>
    )
  }
  return (
    <PromoCountdownWrapper>
      <GradientOverlay />
      <Picture images={pictures} imagesphere={config.imageSphere} widths={[1600]} />
      <CountdownContainer>
        <Title>{teaserTitle}</Title>
        <SubTitle>{subTitlePlain}</SubTitle>
        <Timer>{renderTimeSegments()}</Timer>
        <a href={getLocalisedHref()}>
          <Button onClick={onTargetClick}>{target.teaserTitle}</Button>
        </a>
        <DiscountText>{target.teaserTextPlain}</DiscountText>
      </CountdownContainer>
    </PromoCountdownWrapper>
  )
}

PromoCountdown.layoutVariant = ['promo-countdown']

export default PromoCountdown
